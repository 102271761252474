import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
// import { useActivities } from './useActivities'

import i18n from '@/libs/i18n'
import store from '@/store'

export default {
  components: {},
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentActivity = ref({})
    const activityModalShow = ref(false)
    const activityModalTitle = ref(i18n.t('NewActivity'))

    const addNoteVisibility = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const { submitValidatedActivity } = useActivities()

    // ============================= Start activities =============================
    const resetActivity = () => {
      let dueDate = new Date((new Date()).toISOString().slice(0, 10))
      dueDate.setDate(dueDate.getDate() + 5)

      currentActivity.value = JSON.parse(JSON.stringify({
        dueDate: dueDate,
      }))
    }

    const addActivity = () => {
      // let dueDate = new Date(currentActivity.value.dueDate)
      // dueDate.setDate(dueDate.getDate() + 5)
      // currentActivity.value.dueDate = dueDate.toISOString().slice(0, 10)
      currentActivity.value.assignedTo = store.getters['user/getMe']

      activityModalShow.value = true
    }

    const doneActivity = (activity) => {
      emit('doneActivity', activity)
    }

    const editPlanifiedActivity = (activity) => {
      currentActivity.value = JSON.parse(JSON.stringify(activity))

      activityModalTitle.value = i18n.t('EditActivity')
      activityModalShow.value = true
    }

    const cancelPlanifiedActivity = (activityIndex) => {
      emit('cancelPlanifiedActivity', activityIndex)
    }

    const submitValidatedActivityLocal = () => {
      emit('submitValidatedActivity', JSON.parse(JSON.stringify(currentActivity.value)))

      activityModalShow.value = false
      resetActivity()
    }
    // ============================= End activities =============================

    // ============================= Start notes =============================
    const submitValidatedNoteLocal = (note) => {
      emit('submitValidatedNote', JSON.parse(JSON.stringify(note)))
      addNoteVisibility.value = false
    }
    // ============================= End notes =============================

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetActivity()

    return {
      // Components
      capitalize,

      // Data
      currentActivity,
      activityModalShow,
      activityModalTitle,
      addNoteVisibility,

      // Computed

      // Methods
      addActivity,
      doneActivity,
      editPlanifiedActivity,
      cancelPlanifiedActivity,
      submitValidatedActivityLocal,
      submitValidatedNoteLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}