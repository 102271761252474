<template>
  <div>
    <search-filter
        :items="offers"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        @columnClick="$event.billNumber == null?selectOffer($event):$router.push({ name: 'Offer view', params: { offerId: $event.id }})"
        @addItem="addOffer"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT / {{ currency(getTotalAmounts(items)) }} TTC</span>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-offer
            :offer="item"
            @click="item.billNumber == null?selectOffer(item):$router.push({ name: 'Offer view', params: { offerId: item.id }})"
            @deleteOffer="deleteOfferAlert(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Column status-->
      <template #listView_head_status="{item}">
        <icon icon="chart-line"/>
      </template>
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`offer-row-${item.id}`"
            size="32"
            :variant="`light-${getOfferIconStatus(item).variant}`"
        >
          <icon
              :icon="getOfferIconStatus(item).icon"
          />
        </b-avatar>
        <b-tooltip
            :target="`offer-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ capitalize($t(getOfferStatus(item))) }}
          </p>
        </b-tooltip>
      </template>

      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{ item.customerCompany ? item.customerCompany._display : item.customerIndividual._display }}
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="$router.push({ name: 'Offer view', params: { offerId: item.id }})"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadOfferQuote(item)"
              v-b-tooltip.hover.left="capitalize($t('Download'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


          <b-dropdown
              variant="link"
              toggle-class="p-0"
              right
              no-caret
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Validate-->
            <b-dropdown-item
                v-if="item._isValidated == false && item.isDeclined != true"
                @click.stop="validateOfferLocal(item)"
            >
              <icon
                  icon="check"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('ValidatedOffer') }}</span>
            </b-dropdown-item>

            <!--          Decline-->
            <b-dropdown-item
                v-if="item._isValidated == false && item.isDeclined != true && item.isArchived == false"
                @click.stop="declinedOfferLocal(item, true)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('DeclinedOffer') }}</span>
            </b-dropdown-item>

            <!--          Resume-->
            <b-dropdown-item
                v-if="item.isDeclined == true"
                @click.stop="declinedOfferLocal(item, false)"
            >
              <icon
                  icon="check"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('Resume') }}</span>
            </b-dropdown-item>

            <!--          Edit-->
            <b-dropdown-item
                v-if="item._isValidated == false && item.isDeclined != true"
                @click.native.stop="editOfferFromList(item)"
            >
              <icon
                  icon="edit"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('Edit') }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                v-if="item.isArchived == false"
                @click.stop="archivateOfferLocal(item, true)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Activate-->
            <b-dropdown-item
                v-if="item.isArchived == true"
                @click.stop="archivateOfferLocal(item, false)"
            >
              <icon
                  icon="box-open"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Unarchive')) }}</span>
            </b-dropdown-item>

            <!--          Transform to outgoing invoice-->
            <b-dropdown-item
                v-if="
                item._isValidated == true &&
                item.isArchived == false &&
                item.isCanceled == false &&
                item.isDeclined == false
                "
                @click.native.stop="transformProductsToOutgoingInvoiceLocal(item)"
            >
              <icon
                  icon="file-invoice-dollar"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('transformOfferToOutgoingInvoice')) }}</span>
            </b-dropdown-item>

            <!--          Duplicate -->
            <b-dropdown-item
                v-if="item.isDeclined != true && item.isArchived == false && item.isCanceled == false"
                @click.native.stop="duplicateOffer(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                v-if="item._isValidated == false"
                @click.native.stop="deleteOfferAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>


          </b-dropdown>

        </div>
      </template>


    </search-filter>

    <!-- modal offer-->
    <modal-offer
        :offer.sync="currentOffer"
        :title="offerModalTitle"
        :isOpen.sync="offerModalShow"
        @submitValidatedOffer="submitValidatedOfferLocal"
    />

    <!-- modal offer validation-->
    <modal-offer-validation
        :offer.sync="currentOffer"
        :isOpen.sync="isOfferValidationModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOfferValidationForm"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinOffers } from '../../../views/sales/offers/mixinOffers'
import { useOffers } from '@/views/sales/offers/useOffers'

import SearchFilter from '../../searchFilterV2/SearchFilter'
import CardOffer from '@/components/card/Offer'
import i18n from '@/libs/i18n'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonDropdown from '@/components/button/Dropdown'
import { capitalize } from '../../../utils/filter'
import { getRoute } from '@/utils/utils'

export default {
  components: {
    SearchFilter,
    CardOffer,
    ButtonView,
    ButtonDownload,
    ButtonDropdown
  },
  mixins: [mixinOffers],
  props: {
    offers: {
      type: Array,
      default: () => []
    },
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCustomerCompany',
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isCustomerIndividual',
      },
      {
        text: capitalize(i18n.t('declined')),
        key: '_filter_isDeclined',
      },
      {
        text: capitalize(i18n.t('canceled')),
        key: '_filter_isCanceled',
      },
      {
        text: capitalize(i18n.t('archived')),
        key: '_filter_isArchived',
      },
      {
        text: capitalize(i18n.t('active')),
        key: '_filter_isActive',
        selected: getRoute().name == 'Workflow view'?false:true
      },
      {
        text: capitalize(i18n.t('waitingForSignature')),
        key: '_filter_isWaitingForSignature',
      },
      {
        text: capitalize(i18n.t('waitingForInvoicing')),
        key: '_filter_isWaitingForInvoicing',
      },
      {
        text: capitalize(i18n.t('partiallyInvoiced')),
        key: '_filter_isPartiallyInvoiced',
      },
      {
        text: capitalize(i18n.t('fullyInvoiced')),
        key: '_filter_isFullyInvoiced',
      },
      {
        text: capitalize(i18n.t('myOffers')),
        key: '_filter_myOffers',
        selected: getRoute().name == 'Workflow view'?false:true
      },
    ])

    const columns = ref([
      {
        display: '#',
        key: 'billNumber',
        sortable: true,
      },
      {
        display: capitalize(i18n.t('date')),
        key: 'billingDate',
        sortable: true,
        isDate: true,
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: true
      },
      {
        display: capitalize(i18n.t('customer')),
        key: 'contact',
        sortable: true
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      getOfferStatus,
      getOfferIconStatus,
    } = useOffers()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      sortOptions,
      columns,

      // Computed

      // Methods
      getOfferStatus,
      getOfferIconStatus,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>