var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"m-auto pr-1"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.activity.assignedTo._display),expression:"activity.assignedTo._display",modifiers:{"hover":true,"right":true}}],staticClass:"my-auto",attrs:{"variant":"light-primary","text":_vm.userAvatarText(_vm.activity.assignedTo),"src":_vm.activity.assignedTo.avatar?_vm.activity.assignedTo.avatar.url:''}})],1),_c('div',{staticClass:"w-100 py-50 text-truncate"},[_c('h6',{staticClass:"text-truncate",attrs:{"id":"tooltip-target-1"},domProps:{"innerHTML":_vm._s(_vm.$t('activityTitle', {
                    date: _vm.capitalize(_vm.displayDate(_vm.activity.dueDate)),
                    type: _vm.$t(_vm.activity.type),
                    user: _vm.activity.assignedTo.firstName + ' ' +_vm.activity.assignedTo.name
                  }))}}),_c('b-tooltip',{attrs:{"target":"tooltip-target-1","triggers":"hover"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('activityTitle', {
                    date: _vm.capitalize(_vm.displayDate(_vm.activity.dueDate)),
                    type: _vm.$t(_vm.activity.type),
                    user: _vm.activity.assignedTo.firstName + ' ' +_vm.activity.assignedTo.name
                  }))}})]),_c('small',{staticClass:"mb-0"},[_c('button-done',{attrs:{"withBorder":false,"withIcon":true,"size":'sm'},on:{"click":function($event){return _vm.$emit('doneActivity')}}}),_c('button-edit',{attrs:{"withBorder":false,"withIcon":true,"size":'sm'},on:{"click":function($event){return _vm.$emit('editPlanifiedActivity')}}}),_c('button-cancel',{attrs:{"withBorder":false,"withIcon":true,"size":'sm'},on:{"click":function($event){return _vm.$emit('cancelPlanifiedActivity')}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }