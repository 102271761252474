<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ capitalize($t('monitoring')) }}</b-card-title>

        <div class="cursor-pointer">

          <button-note
              :withBorder="false"
              @click.native="addNoteVisibility=!addNoteVisibility"
              size="sm"/>

          <button-activity
              :withBorder="false"
              @click.native="addActivity"
              size="sm"/>
        </div>
      </b-card-header>

      <b-card-body class="pb-0">

        <!--        Add note-->
        <transition name="fade">
          <div v-if="addNoteVisibility"
               class="mb-2">
            <add-note
                @submitValidatedNoteLocal="submitValidatedNoteLocal($event)"
            />
          </div>
        </transition>


        <!--        Planified activities-->
        <div>
          <div
              @click="planifiedVisibility=!planifiedVisibility"
              class="w-100 text-center mb-1 cursor-pointer"
          >
            <icon :icon="planifiedVisibility?'angle-up':'angle-down'"
                  class="mr-1"/>
            {{ $t('PlanifiedActivities') }} ({{ undoneActivities.length }})
          </div>

          <transition name="fade">
            <div v-if="planifiedVisibility">
              <b-row
                  v-for="(activity, index) in undoneActivities"
                  :key="index"
              >
                <b-col cols="12 my-50">
                  <planified-activity
                      :activity="activity"
                      @doneActivity="doneActivity(activity)"
                      @editPlanifiedActivity="editPlanifiedActivity(activity)"
                      @cancelPlanifiedActivity="cancelPlanifiedActivity(index)"
                  />
                </b-col>
              </b-row>
            </div>
          </transition>

        </div>


        <!--  Comments-->
        <div class="mt-2">
          <div
              @click="commentsVisibility=!commentsVisibility"
              class="w-100 text-center mb-1 cursor-pointer"
          >
            <icon :icon="commentsVisibility?'angle-up':'angle-down'"
                  class="mr-1"/>
            {{ capitalize($tc('comment', 2)) }} ({{ notes.length }})
          </div>

          <transition name="fade">
            <div v-if="commentsVisibility">
              <monitoring
                  v-for="(note, index) in notes"
                  :key="index"
                  class="monitoring"
              >
                <template #icon>
                  <b-avatar
                      variant="light-primary"
                      :text="userAvatarText(note.by)"
                      :src="note.by.avatar?note.by.avatar.url:''"
                      class=""
                  />
                </template>

                <template #title>
                  <span class="text-primary">{{ note.by._display }}</span>
                </template>

                <template #date>
                  {{ note.date|moment('from','now') }}
                </template>

                <template #content>
                  <small v-html="note.content"/>
                </template>

                <template
                    v-if="note.files.length"
                    #files
                >
                  <b-row>
                    <b-col
                        md="6"
                        v-for="(document, j) in note.files"
                        :key="j"
                    >
                      <card-document
                          :document="document"
                          :isDeletable="false"
                      />
                    </b-col>
                  </b-row>
                </template>

              </monitoring>

            </div>
          </transition>

        </div>

        <!--  History-->
        <div class="mt-2 mb-1">
          <div
              @click="historyVisibility=!historyVisibility"
              class="w-100 text-center mb-1 cursor-pointer"
          >
            <icon :icon="historyVisibility?'angle-up':'angle-down'"
                  class="mr-1"/>
            {{ capitalize($t('history')) }} ({{ history.length }})
          </div>

          <transition name="fade">
            <div v-if="historyVisibility">
              <monitoring
                  v-for="(item, index) in history"
                  :key="index"
                  class="monitoring"
              >
                <template #icon>
                  <b-avatar
                      variant="light-primary"
                      :text="userAvatarText(item.by)"
                      :src="item.by.avatar?item.by.avatar.url:''"
                      class=""
                  />
                </template>

                <template #title>
                  {{ item.by._display }}
                </template>

                <template #date>
                  {{ item.date|moment('from','now') }}
                </template>

                <template #content>
                  <small v-html="item.content"/>
                </template>

                <template
                    v-if="item.files.length"
                    #files
                >
                  <b-row>
                    <b-col
                        md="6"
                        v-for="(document, j) in item.files"
                        :key="j"
                    >
                      <card-document
                          :document="document"
                          :isDeletable="false"
                      />
                    </b-col>
                  </b-row>
                </template>

              </monitoring>

            </div>
          </transition>

        </div>

      </b-card-body>
    </b-card>

    <!-- modal activity-->
    <modal-activity
        :activity.sync="currentActivity"
        :title="activityModalTitle"
        :isOpen.sync="activityModalShow"
        @submitValidatedActivity="submitValidatedActivityLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, userAvatarText } from '@/utils/filter'

import mixinMonitoring from './mixinMonitoring'
import ModalActivity from '@/components/prompt/Activity'
import PlanifiedActivity from './components/_panifiedActivity'
import AddNote from './components/_addNote'
import Monitoring from './components/_monitoring'
import CardDocument from '@/components/card/Document'
import ButtonNote from '@/components/button/Note'
import ButtonActivity from '@/components/button/Activity'

export default {
  components: {
    ModalActivity,
    PlanifiedActivity,
    AddNote,
    Monitoring,
    CardDocument,
    ButtonNote,
    ButtonActivity
  },
  mixins: [mixinMonitoring],
  props: {
    activities: {
      type: Array,
      default: () => []
    },
    // notes: {
    //   type: Array,
    //   default: () => []
    // },
    monitoring: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const planifiedVisibility = ref(true)
    const commentsVisibility = ref(true)
    const historyVisibility = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const undoneActivities = computed(() => {
      return props.activities.filter(act => !act.isDone)
    })

    const notes = computed(() => {
      return props.monitoring.filter(m => m.content.startsWith('<p>'))
    })

    const history = computed(() => {
      return props.monitoring.filter(m => !m.content.startsWith('<p>'))
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const displayDate = (date) => {
    //   return moment(date).fromNow()
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      userAvatarText,

      // Data
      planifiedVisibility,
      commentsVisibility,
      historyVisibility,

      // Computed
      undoneActivities,
      notes,
      history,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.monitoring {
  p {
    margin-bottom: 0;
  }
}

</style>