<template>
  <generic-indicator-card
      v-if="displayCard"
      :toDonut="toDonut"
      :donutTotal="percent(rentability)"
      :isDonutCurrencies="true"
      :from="from"
      :to="to"
  >
    <template #header>
      <h4 v-b-tooltip.hover.bottom="capitalize($t('rentability'))">
        {{ capitalize($t('rentability')) }}
        <br/>
        {{ percent(rentability) }}
        <br/>
        <div class="text-truncate">
          {{ currency(invoicedCurrentPurchase, true) }} / {{ currency(invoicedCurrentSales, true) }}
        </div>
      </h4>
    </template>

    <template #indicators>
      <b-row>

        <!--          Rentability-->
        <b-col
            md="6"
            v-b-tooltip.hover.bottom="capitalize($t('rentability:')) + ' '+ percent(rentability) + ' /' + percent(100, 0)"
        >
          <label>
            {{ capitalize($t('rentability:')) }}&nbsp;{{ percent(rentability) }} / {{
              percent(100, 0)
            }}
          </label>
          <b-progress
              v-model="rentability"
              :max="100"
              animated
              variant="primary"
              :class="'progress-bar-primary'"
          />
        </b-col>

        <!--          Duration-->
        <!--          <b-col-->
        <!--              v-if="workflow.closingDate != null"-->
        <!--              md="6"-->
        <!--              v-b-tooltip.hover.bottom="capitalize($t('budget:')) + ' '+ currency(currentPurchased, true) + ' /' +  currency(workflow.potentialExpenses, true)"-->
        <!--          >-->
        <!--            <label>-->
        <!--              {{ capitalize($t('budget:')) }}&nbsp;{{ currency(currentPurchased, true) }} / {{-->
        <!--                currency(workflow.potentialExpenses, true)-->
        <!--              }}-->
        <!--            </label>-->
        <!--            <b-progress-->
        <!--                v-model="currentPurchased"-->
        <!--                :max="workflow.potentialExpenses"-->
        <!--                animated-->
        <!--                variant="primary"-->
        <!--                :class="'progress-bar-primary'"-->
        <!--            />-->
        <!--          </b-col>-->


      </b-row>
    </template>

  </generic-indicator-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, percent, currency, number } from '@/utils/filter'
import { useIndicators } from '../../../../../components/indicators/useIndicators'

import GenericIndicatorCard from './_GenericCard'
import i18n from '@/libs/i18n'

export default {
  components: {
    GenericIndicatorCard
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String
    },
    to: {
      type: String
    },
    selectedPhases: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const invoicedCurrentPurchase = computed(() => {
      return getInvoicedCurrentPurchase(
          props.workflow,
          props.from,
          props.to,
          props.selectedPhases)
    })
    const invoicedCurrentSales = computed(() => {
      return getInvoicedCurrentSales(
          props.workflow,
          props.from,
          props.to,
          props.selectedPhases)
    })

    const rentability = computed(() => {
      if (invoicedCurrentSales.value == 0) return -parseFloat(invoicedCurrentPurchase.value)
      else return (invoicedCurrentSales.value - invoicedCurrentPurchase.value) * 100 / invoicedCurrentSales.value
    })

    const toDonut = computed(() => {
      return {
        labels: [
          capitalize(i18n.tc('expense', 2)),
          i18n.t('Sales'),
        ],
        values: [
          invoicedCurrentPurchase.value,
          invoicedCurrentSales.value,
        ],
        total: rentability.value
      }
    })

    const displayCard = computed(() => {
      return true
      // return (
      //     props.selectedPhases.length == 0 &&
      //     invoicedCurrentPurchase.value != 0 &&
      //     invoicedCurrentSales.value !=0
      // )

    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      getInvoicedCurrentSales,
      getInvoicedCurrentPurchase
    } = useIndicators()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      percent,
      currency,
      number,

      // Data

      // Computed
      rentability,
      invoicedCurrentPurchase,
      invoicedCurrentSales,
      toDonut,
      displayCard,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>