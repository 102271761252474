<template>
  <div>
<!--    <offer-home-->
<!--        v-if="workflow"-->
<!--        :offers="workflow.offers"-->
<!--    />-->

    <offers-view

        @reloadOffers="reloadOffers($event)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import router from '@/router'

import offerHome from '@/components/home/offer/Offer'
import OffersView from '../../../../components/views/OffersView.vue'
import useAPI from '../../../../utils/useAPI'

export default {
  components: {
    offerHome,
    OffersView
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      fetchAutocompleteArticles,
      fetchAutocompleteContacts,
      fetchAutocompletePaymentMethods,
      fetchAutocompletePaymentTerms,
      fetchAutocompleteVats,
      fetchAutocompleteWorkflows,
      fetchOffers2
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const reloadOffers = (event) => {
      let payload = event
      if (event == null) {
        payload = {
          searchCriteria: [
            {
              column_name: 'workflow',
              comparator: '=',
              search_text: router.history.current.params.workflowId
            }
          ]
        }
      } else {
        let scWorkflowPosition = event.searchCriteria.find(sc => sc.column_name == 'workflow')
        if (scWorkflowPosition == undefined) {
          payload.searchCriteria.push({
            column_name: 'workflow',
            comparator: '=',
            search_text: router.history.current.params.workflowId
          })
        } else {
          payload.searchCriteria[scWorkflowPosition] = {
            column_name: 'workflow',
            comparator: '=',
            search_text: router.history.current.params.workflowId
          }
        }
      }

      fetchOffers2(payload)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    reloadOffers()

    fetchAutocompleteArticles()
    fetchAutocompleteContacts()
    fetchAutocompletePaymentMethods()
    fetchAutocompletePaymentTerms()
    fetchAutocompleteVats()
    fetchAutocompleteWorkflows()

    return {
      // Components

      // Data

      // Computed

      // Methods
      reloadOffers,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>