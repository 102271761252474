<template>
  <generic-card
      @click="$emit('click')"
      @delete="$emit('deleteOffer')"
      :allowDelete="offer.billNumber == null"
  >

    <template #iconContent>
      <b-avatar
          size="32"
          :variant="'light-'+iconStatus.variant"
      >
        <icon
            :icon="iconStatus.icon"
        />
      </b-avatar>
    </template>

    <template #bodyContent>
      <h4>{{ offer.title }}</h4>

      <div class="truncate">
        {{ contact._display }}
      </div>
      <div class="truncate d-flex justify-content-between">
        <span>{{ offer.billingDate | moment("L")}}</span>
        <b>{{ currency(offer.preTaxAmount) }}</b>
      </div>

    </template>
  </generic-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '@/utils/filter'
import {useOffers} from '@/views/sales/offers/useOffers'

import GenericCard from './Card'

export default {
  components: {
    GenericCard
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconStatus = computed(() => {
      return getOfferIconStatus(props.offer)
    })

    const contact = computed(() => {
      if (props.offer.customerCompany != null) {
        return props.offer.customerCompany
      } else {
        return props.offer.customerIndividual
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {getOfferIconStatus} = useOffers()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data
      iconStatus,
      contact

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>