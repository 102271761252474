import store from '../../../store'
import { useOffers } from './useOffers'
import { ref, watch } from '@vue/composition-api'

import router from '../../../router'
import useAPI from '../../../utils/useAPI'

export const mixinOffers = {
  components: {},
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentOffer = ref({})
    const offerModalShow = ref(false)
    const isOfferValidationModalOpen = ref(false)
    const selectedProducts = ref([])
    const currentWorkflow = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteWorkflows } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(offerModalShow, (val) => {
      if (val == false) {
        resetOffer()
      }
    })

    watch(autocompleteWorkflows, val => {
      if (router.history.current.params.workflowId) {
        let wf = autocompleteWorkflows.value.find(w => w.id == router.history.current.params.workflowId)
        currentWorkflow.value = store.getters['workflow/getWorkflow'](router.history.current.params.workflowId)
        currentOffer.value.workflow = wf

        resetOffer()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      downloadOfferQuote,
      submitValidatedOffer,
      removeOffer,
      transformProductsToOutgoingInvoice
    } = useOffers()

    const submitValidatedOfferLocal = () => {
      submitValidatedOffer(currentOffer.value)
        .then(() => {
          offerModalShow.value = false
          resetOffer()
        })
    }

    const editOffer = (offer) => {
      store.dispatch('offer2/getOffer', offer.id)
        .then(response => {
          let expirationDate = new Date()
          expirationDate.setDate(expirationDate.getDate() + 30)

          currentOffer.value = response
          currentOffer.value.billingDate = (new Date()).toISOString().slice(0, 10)
          currentOffer.value.expirationDate = expirationDate.toISOString().slice(0, 10)
          offerModalShow.value = true
        })
    }

    const duplicateOffer = (offer) => {
      store.dispatch('offer2/getOffer', offer.id)
        .then(response => {
          currentOffer.value = response

          delete currentOffer.value.id
          delete currentOffer.value.logs
          delete currentOffer.value.orders
          delete currentOffer.value.receipt

          offerModalShow.value = true
        })
    }

    const removeOfferLocal = (offer) => {
      removeOffer(offer)
        .then(response => {
          // if (getRoute().name == 'Offer view') {
          //   router.push({ name: 'Offers' })
          // } else if (getRoute().name == 'Workflow view') {
          //   let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
          //   workflow.offers = workflow.offers.filter(ii => ii.id != offer.id)
          // }
        })
    }

    const resetOffer = () => {
      currentOffer.value = JSON.parse(JSON.stringify(store.getters['offer2/getEmptyOffer']))

      if (currentWorkflow.value != null) {
        currentOffer.value.workflow = currentWorkflow.value
        currentOffer.value.customerCompany = currentWorkflow.value.customerCompany
        currentOffer.value.customerIndividual = currentWorkflow.value.customerIndividual
      }

    }

    const validateOfferLocal = (offer) => {
      store.dispatch('offer2/getOffer', offer.id)
        .then(response => {
          console.log(response)
          currentOffer.value = response
          // populateWorkflow(currentOffer.value)
          currentOffer.value.signedDocumentDate = (new Date()).toISOString().slice(0, 10)
          isOfferValidationModalOpen.value = true
        })
    }

    const submitValidatedOfferValidationForm = () => {
      console.log("ok")
      submitValidatedOffer(currentOffer.value)
        .then(response => {
console.log(response)
          if (response.data.workFlo != null) {
            let workFlo = store.getters['workFlo/getWorkFlo'](response.data.workFlo.id)

            console.log(workFlo)
            workFlo.status = {
              id: 5,
              name: "Commande"
            }
            store.commit('workFlo/REMOVE_WORKFLO', workFlo)
          }

          // updateWorkflow(response.data)
          resetOffer()
          isOfferValidationModalOpen.value = false
        })
    }

    const declinedOfferLocal = (offer, isDeclined) => {
      store.dispatch('offer2/getOffer', offer.id)
        .then(response => {
          currentOffer.value = response
          // populateWorkflow(currentOffer.value)
          currentOffer.value.isDeclined = isDeclined

          submitValidatedOffer(currentOffer.value)
            .then(response => {
              // updateWorkflow(response.data)
              resetOffer()
            })
        })
    }

    const archivateOfferLocal = (offer, isArchived) => {
      store.dispatch('offer2/getOffer', offer.id)
        .then(response => {
          currentOffer.value = response
          // populateWorkflow(currentOffer.value)
          currentOffer.value.isArchived = isArchived

          submitValidatedOffer(currentOffer.value)
            .then(response => {
              // updateWorkflow(response.data)

              // currentOffer.value = response.data


              resetOffer()
            })
        })
    }

    const transformProductsToOutgoingInvoiceLocal = (offer) => {
      store.dispatch('offer2/getOffer', offer.id)
        .then(response => {
          offer = response
          if (selectedProducts.value.length == 0) {
            selectedProducts.value = offer.products.filter(p => p.outgoingInvoice == null)
          }
          let selectedProductsIds = selectedProducts.value.map(p => p.id)

          transformProductsToOutgoingInvoice(offer, selectedProducts.value)
            .then(response => {
              selectedProducts.value = []

              offer.products.forEach(p => {
                if (p.outgoingInvoice == null) {
                  selectedProductsIds.forEach(selectedId => {
                    if (selectedId == p.id) {
                      p.outgoingInvoice = response.data
                    }
                  })
                }
              })
            })
        })



    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetOffer()

    return {
      // Components

      // Data
      currentOffer,
      offerModalShow,
      isOfferValidationModalOpen,
      selectedProducts,

      // Computed

      // Methods
      submitValidatedOfferLocal,
      editOffer,
      duplicateOffer,
      removeOfferLocal,
      validateOfferLocal,
      submitValidatedOfferValidationForm,
      declinedOfferLocal,
      archivateOfferLocal,
      downloadOfferQuote,
      transformProductsToOutgoingInvoiceLocal,

    }
  },
  data () {
    return {}
  },
  methods: {
    deleteOfferAlert (offer) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theOffer') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeOfferLocal(offer)
          }
        })
    }
  },
}