<template>
  <div>

    <base-template
        :title="$tc('offer',2)"
        itemView="list"
        :columns="columns"
        :filters="filters"
        :items="offers2"
        :context="offersContext"
        :isActionColumnEnabled="true"
        :openViewWindow.sync="openViewWindow"
        :allow-add="allowAdd"
        :display-custom-fields="displayCustomFields&&allowAdd"

        @fetch="$emit('reloadOffers', $event)"
        @selectItem="showDetails"
        @addItem="offerModalShow=true"
    >
      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <offer-card
            :offer="item"
            :is-downloadable="false"
            :is-viewable="true"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Validate-->
          <button-validate
              v-if="item._isValidated == false && item.isDeclined != true"
              @click.native.stop="validateOfferLocal(item)"
              v-b-tooltip.hover.left="$t('ValidatedOffer')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Decline-->
          <button-close
              v-if="item._isValidated == false && item.isDeclined != true && item.isArchived == false"
              @click.native.stop="declinedOfferLocal(item, true)"
              v-b-tooltip.hover.left="$t('DeclinedOffer')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Transform to outgoing invoice-->
          <button-to-invoice
              v-if="item._isValidated == true &&
                item.isArchived == false &&
                item.isCanceled == false &&
                item.isDeclined == false"
              @click.native.stop="transformProductsToOutgoingInvoiceLocal(item)"
              v-b-tooltip.hover.left="capitalize($t('transformOfferToOutgoingInvoice'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit -->
          <button-edit
              v-if="allowEdit"
              @click.native.stop="editOffer(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :disabled="item._isValidated"
              size="sm"
          />

          <!--          Download-->
          <button-download
              v-b-tooltip.hover.left="$t('Download')"
              @click.native.stop="downloadOfferQuote(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Duplicate-->
          <button-duplicate
              v-if="allowAdd"
              v-b-tooltip.hover.left="capitalize($t('duplicate'))"
              @click.native.stop="duplicateOffer(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Archivate-->
          <button-archivate
              v-if="item.isArchived == false"
              v-b-tooltip.hover.left="capitalize($t('Archivate'))"
              @click.native.stop="archivateOfferLocal(item, true)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Unarchivate-->
          <button-unarchivate
              v-if="item.isArchived == true"
              v-b-tooltip.hover.left="capitalize($t('Unarchive'))"
              @click.native.stop="archivateOfferLocal(item, false)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="allowRemove"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              @click.native.stop="deleteOfferAlert(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :disabled="item._isValidated"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <offer-card
            :offer="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0].billNumber }} - {{ selectedItems[0].title }}</h4>
          <div>

            <!--          Validate-->
            <button-validate
                v-if="selectedItems[0]._isValidated == false && selectedItems[0].isDeclined != true"
                @click.native.stop="validateOfferLocal(selectedItems[0])"
                v-b-tooltip.hover.left="$t('ValidatedOffer')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Decline-->
            <button-close
                v-if="selectedItems[0]._isValidated == false && selectedItems[0].isDeclined != true && selectedItems[0].isArchived == false"
                @click.native.stop="declinedOfferLocal(selectedItems[0], true)"
                v-b-tooltip.hover.left="$t('DeclinedOffer')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Transform to outgoing invoice-->
            <button-to-invoice
                v-if="selectedItems[0]._isValidated == true &&
                selectedItems[0].isArchived == false &&
                selectedItems[0].isCanceled == false &&
                selectedItems[0].isDeclined == false"
                @click.native.stop="transformProductsToOutgoingInvoiceLocal(selectedItems[0])"
                v-b-tooltip.hover.left="capitalize($t('transformOfferToOutgoingInvoice'))"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit -->
            <button-edit
                v-if="allowEdit"
                @click.native.stop="editOffer(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :disabled="selectedItems[0]._isValidated"
                size="sm"
            />

            <!--          Download-->
            <button-download
                v-b-tooltip.hover.left="$t('Download')"
                @click.native.stop="downloadOfferQuote(selectedItems[0])"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Duplicate-->
            <button-duplicate
                v-if="allowAdd"
                v-b-tooltip.hover.left="capitalize($t('duplicate'))"
                @click.native.stop="duplicateOffer(selectedItems[0])"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Archivate-->
            <button-archivate
                v-if="selectedItems[0].isArchived == false"
                v-b-tooltip.hover.left="capitalize($t('Archivate'))"
                @click.native.stop="archivateOfferLocal(selectedItems[0], true)"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Unarchivate-->
            <button-unarchivate
                v-if="selectedItems[0].isArchived == true"
                v-b-tooltip.hover.left="capitalize($t('Unarchive'))"
                @click.native.stop="archivateOfferLocal(selectedItems[0], false)"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <offer-view
            :offer="selectedItems[0]"
            :selected-products.sync="selectedProducts"
        />


      </template>
    </base-template>

    <!-- modal incoming invoice-->
    <modal-offer
        :offer.sync="currentOffer"
        :title="('id' in currentOffer)?$t('EditOffer'):$t('NewOffer')"
        :is-open.sync="offerModalShow"
        @submitValidatedOffer="submitValidatedOfferLocal"
    />

    <!-- modal offer validation-->
    <modal-offer-validation
        :offer.sync="currentOffer"
        :isOpen.sync="isOfferValidationModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOfferValidationForm"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useOffers } from '../../views/sales/offers/useOffers'
import { capitalize, currency } from '../../utils/filter'
import { mixinOffers } from '../../views/sales/offers2/mixinOffers'

import BaseTemplate from '../base/Base'
import OfferCard from '../card/Offer2'
import ButtonView from '../button/View'
import ButtonDownload from '../button/Download'
import ButtonClose from '../button/Close'
import ButtonEdit from '../button/Edit.vue'
import ButtonDuplicate from '../button/Duplicate.vue'
import ButtonRemove from '../button/Remove.vue'
import ButtonValidate from '../button/Validate.vue'
import ButtonArchivate from '../button/Archivate.vue'
import ButtonUnarchivate from '../button/Unarchivate.vue'
import ButtonToInvoice from '../button/ToInvoice2.vue'
import ModalOffer from '../prompt/Offer.vue'
import ModalOfferValidation from '../prompt/OfferValidation.vue'
import OfferView from './OfferView.vue'

import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import store from '../../store'

export default {
  components: {
    BaseTemplate,
    OfferCard,
    ButtonView,
    ButtonDownload,
    ButtonClose,
    ButtonEdit,
    ButtonDuplicate,
    ButtonRemove,
    ButtonValidate,
    ButtonArchivate,
    ButtonUnarchivate,
    ButtonToInvoice,
    ModalOffer,
    ModalOfferValidation,
    OfferView,
  },
  mixins: [mixinOffers],
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    allowPayment: {
      type: Boolean,
      default: true
    },
    disabledFilters: {
      type: Array,
      default: () => []
    },
    defaultSelectedFilters: {
      type: Array,
      default: () => []
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('number')),
        key: 'billNumber',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('billingDate')),
        key: 'billingDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: true,
        cell: {
          type: 'offerStatus',
          useIcon: true
        }
      },
      {
        display: capitalize(i18n.t('customer')),
        key: 'contact',
        sortable: true,
        cell: {
          isContact: true
        }
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCustomerCompany',
        searchCriteria: [{
          column_name: 'customerCompany',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isCustomerIndividual',
        searchCriteria: [{
          column_name: 'customerIndividual',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('canceled')),
        key: '_filter_isCanceled',
        searchCriteria: [{
          column_name: 'isCanceled',
          comparator: 'true',
        }],
      },
      {
        text: capitalize(i18n.tc('archived')),
        key: '_filter_isArchived',
        searchCriteria: [{
          column_name: 'isArchived',
          comparator: 'true',
        }],
      },
      {
        text: capitalize(i18n.tc('active')),
        key: '_filter_isActive',
        searchCriteria: [{
          column_name: 'isArchived',
          comparator: 'false',
        }],
        selected: true
      },
      // {
      //   text: capitalize(i18n.tc('waitingForSignature')),
      //   key: '_filter_isWaitingForSignature',
      //   searchCriteria: [{
      //     column_name: 'signedDocumentDate',
      //     comparator: 'notNull',
      //   }],
      // },
      {
        text: capitalize(i18n.tc('myOffers')),
        key: '_filter_myOffers',
        searchCriteria: [{
          column_name: 'createdBy',
          comparator: 'me',
        }],
      },

    ])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const offerByUploadModalShow = ref(false)
    const selectedOffer = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      offers2,
      offersContext
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(offers2, val => {
      if (openViewWindow.value == true && selectedItems.value.length) {
        if (
            JSON.stringify(val.find(o => o.id == selectedItems.value[0].id)) !==
            JSON.stringify(selectedItems.value[0])
        ) {
          console.log('changé !')
          selectedItems.value[0] = val.find(o => o.id == selectedItems.value[0].id)
        }
      }
    })

    watch(() => offers2, val => {
      console.log('changé !')
      console.log(val.value)
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      downloadOfferQuote,
    } = useOffers()

    const showDetails = (offer) => {
      if (offer != null) {
        store.dispatch('offer2/getOffer', offer.id)
            .then(response => {
              selectedOffer.value = response
              selectedItems.value = []
              selectedItems.value[0] = selectedOffer.value

              // console.log(selectedOffer.value)

              openViewWindow.value = true

              // selectedItems.value = []
              // selectedItems.value[0] = response
              // openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      offerByUploadModalShow,

      // Computed
      offers2,
      offersContext,

      // Methods
      showDetails,
      downloadOfferQuote,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>