<template>
  <div class="d-flex justify-content-between">
    <div class="pt-1 pr-1">
      <slot name="icon">
        <b-avatar
            variant="light-primary"
            class="my-auto"
        />
      </slot>
    </div>

    <div class="w-100 py-50">
      <div class="d-flex justify-content-between">
        <div class="text-truncate">
          <slot name="title">&nbsp;</slot>
        </div>
        <small class="text-nowrap text-muted my-auto">
          <slot name="date">&nbsp;</slot>
        </small>
      </div>
      <div>
        <slot name="content">&nbsp;</slot>
      </div>

      <slot name="files"></slot>

    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>