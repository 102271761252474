<template>
  <div>
    <div
        v-for="(timeByUser,index) in timeSpentByUsersOrdered"
        :key="index"
        class="d-flex justify-content-between"
        :class="index === timeSpentByUsers.length - 1 ? 'mb-0':'mb-1'"
    >
      <div class="series-info d-flex align-items-center">
        <span class="font-weight-bolder ml-75 mr-25">{{ timeByUser.name }}</span>
        <!--        <span> - {{ getTime(timeByUser)|duration('humanize') }}</span>-->
        <span> - {{ getTime(timeByUser) }}</span>
      </div>
      <div>
        <span>{{ percent(getPercent(timeByUser), 1) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { percent } from '@/utils/filter'

import moment from 'moment'
import i18n from '../../libs/i18n'

export default {
  components: {},
  props: {
    timeSpentByUsers: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // console.log(props.timeSpentByUsers)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const totalTime = computed(() => {
      let total = 0

      props.timeSpentByUsers.forEach(t => total += t.duration)

      return total
    })

    const timeSpentByUsersOrdered = computed(() => {
      let timeSpentByUsersOrdered = JSON.parse(JSON.stringify(props.timeSpentByUsers))
      return timeSpentByUsersOrdered.sort((a, b) => {
        return a.duration > b.duration ? -1 : ((a.duration < b.duration) ? 1 : 0)
      })
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getTime = (timeSpent) => {
      let duration = moment.duration(timeSpent.duration, 'hours')

      return duration.days() + ' ' + i18n.tc('day', duration.days()) + ' ' +
          duration.hours() + ' ' + i18n.tc('hour', duration.hours()) + ' ' +
          duration.minutes() + ' ' + i18n.tc('minute', duration.minutes())
    }

    const getPercent = (timeSpent) => {
      return timeSpent.duration * 100 / totalTime.value
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      percent,

      // Data

      // Computed
      timeSpentByUsersOrdered,

      // Methods
      getTime,
      getPercent,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>