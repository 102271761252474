import { ref, computed, watch } from '@vue/composition-api'
import { useWorkflows } from './useWorkflows'
import { capitalize } from '@/utils/filter'
import { getUserData, getRoute } from '../../utils/utils'

import ModalWorkflow from '@/components/prompt/Workflow'
import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'
import workflow from '../../store/workflow/workflow'
import moment from 'moment'
import { useSearchFilter } from '../../components/searchFilterV2/useSearchFilter'
import useAPI from '../../utils/useAPI'

export default {
  components: {
    ModalWorkflow
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentWorkflow = ref({})
    const workflowModalShow = ref(false)
    const workflowModalTitle = ref(i18n.t('NewWorkflow'))
    const firstLoad = ref(true)
    const calendarStartDate = ref(null)
    const calendarEndDate = ref(null)
    const isMounted = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workflow = computed(() => {
      let workflow = store.getters['workflow/getWorkflow'](router.history.current.params.workflowId)

      if (
        typeof workflow != 'undefined' &&
        'activities' in workflow
      ) {
        // Mounted
        workflow.tasks = workflow.tasks.filter(t => t.logs.at(-1).by.id == getUserData().id)

        return workflow
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(workflowModalShow, (val) => {
      if (val == false) {
        resetWorkflow()
      }
    })

    // watch(workflow, (val) => {
    //   fetchCalendars(val.events)
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchAutocompleteContacts,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteArticles,
      fetchAutocompleteBankAccounts,
      fetchAutocompleteVats,
      fetchAutocompleteWorkflows,
      fetchArticles,
      fetchCompanies,
      fetchIncomingInvoices,
      fetchOffers,
      fetchOrders,
      fetchOutgoingInvoices,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchVats,
      fetchUsers2,
      fetchWorkflows,
      fetchCalendars,
    } = useAPI()

    const { displayLastLogs } = useSearchFilter()

    const {
      submitValidatedWorkflow,
      activateWorkflow,
      archivateWorkflow,
      removeWorkflow,
    } = useWorkflows()

    const resetWorkflow = () => {
      workflowModalTitle.value = i18n.t('NewWorkflow')
      currentWorkflow.value = JSON.parse(JSON.stringify(store.getters['workflow/getEmptyWorkflow']))
    }

    const addWorkflow = () => {
      currentWorkflow.value.responsible = store.getters['user/getMe']

      workflowModalShow.value = true
    }

    const selectWorkflow = (workflow) => {
      workflowModalTitle.value = i18n.t('EditWorkflow')

      fetchWorkflows(workflow.id).then((workflow) => {
        currentWorkflow.value = workflow
        workflowModalShow.value = true
      })

    }

    const activateWorkflowLocal = (workflow) => {
      fetchWorkflows(workflow.id).then((workflow) => {
        activateWorkflow(workflow)
      })
    }

    const archivateWorkflowLocal = (workflow) => {
      fetchWorkflows(workflow.id).then((workflow) => {
        archivateWorkflow(workflow)
      })
    }

    const duplicateWorkflow = (workflow) => {
      fetchWorkflows(workflow.id).then((workflow) => {
        currentWorkflow.value = workflow
        delete currentWorkflow.value.id
        delete currentWorkflow.value.logs
        delete currentWorkflow.value.events
        delete currentWorkflow.value.incomingInvoices
        delete currentWorkflow.value.offers
        delete currentWorkflow.value.orders
        delete currentWorkflow.value.outgoingInvoices

        workflowModalShow.value = true
      })
    }

    const submitValidatedWorkflowLocal = () => {
      submitValidatedWorkflow(currentWorkflow.value)
        .then(response => {
          // resetWorkflow()
          workflowModalShow.value = false
        })
    }

    // ========================= Start activity =========================
    const doneActivity = (activity) => {
      let act = JSON.parse(JSON.stringify(activity))
      act.isDone = true

      submitValidatedActivity(act)
    }

    const submitValidatedActivity = (activity) => {
      let editedWorkflow = JSON.parse(JSON.stringify(workflow.value))

      if ('id' in activity && activity.id != null) {
        let index = editedWorkflow.activities.findIndex(act => act.id == activity.id)
        editedWorkflow.activities.splice(index, 1, JSON.parse(JSON.stringify(activity)))
      } else {
        editedWorkflow.activities.push(activity)
      }

      submitValidatedWorkflow(editedWorkflow)

    }

    const cancelPlanifiedActivity = (activityIndex) => {
      let editedWorkflow = JSON.parse(JSON.stringify(workflow.value))
      editedWorkflow.activities.splice(activityIndex, 1)

      submitValidatedWorkflow(editedWorkflow)
    }
    // ========================= End activity =========================

    // ============================= Start notes =============================
    const submitValidatedNote = (note) => {
      let editedWorkflow = JSON.parse(JSON.stringify(workflow.value))

      if ('id' in note && note.id != null) {
        let index = editedWorkflow.notes.findIndex(nte => nte.id == note.id)
        editedWorkflow.notes.splice(index, 1, JSON.parse(JSON.stringify(note)))
      } else {
        editedWorkflow.notes.push(note)
      }

      submitValidatedWorkflow(editedWorkflow)
    }
    // ============================= End notes =============================

    const removeWorkflowLocal = (workflow) => {
      removeWorkflow(workflow)
    }

    const calendarDatesChange = (dates) => {
      calendarStartDate.value = dates.start
      calendarEndDate.value = dates.end

      // if ('events' in workflow) {
      //   console.log(workflow)
      //   let payload = {
      //     dates : dates,
      //     databaseEvents: workflow.events
      //   }
      //
      //   fetchCalendars(payload).then(() => {
      //     firstLoad.value = false
      //   })
      //
      //   if (firstLoad.value) {
      //     fetchCalendars(payload)
      //   }
      // }

    }

    const fetchForDetails = () => {
      fetchUsers2().then(() => {
        isMounted.value = true

        // fetchCompanies().then(() => {
        //   if (workflow.value.customerCompany != null) {
        //     fetchCompanies(workflow.value.customerCompany.id)
        //   } else {
        //     fetchUsers2(workflow.value.customerIndividual.id)
        //   }
        // })
      })
      // fetchUsers2(getUserData().id)

      // console.log(workflow)
      let payload = {
        dates: {
          start: calendarStartDate.value,
          end: calendarEndDate.value,
        },
        databaseEvents: workflow.value.events
      }
      fetchCalendars(payload)

      fetchAutocompleteContacts()
      fetchAutocompletePaymentMethods()
      fetchAutocompleteArticles()
      fetchAutocompleteBankAccounts()
      fetchAutocompleteVats()
      fetchAutocompleteWorkflows()

      // fetchArticles()
      // fetchCompanies()
      // fetchIncomingInvoices()
      // fetchOffers()
      // fetchOrders()
      // fetchOutgoingInvoices()
      fetchPaymentMethods()
      fetchPaymentTerms()
      fetchVats()

    }

    // const displayLastLogs = (workflow) => {
    //   if (workflow.logs.length == 1) {
    //     return (moment(workflow.logs[0].at).format('L') + ' : ' + i18n.t('creation'))
    //   } else {
    //     let output = []
    //     Object.keys(workflow.logs[workflow.logs.length - 1].data).forEach(key => {
    //       // let value = moment(workflow.logs[workflow.logs.length - 1].at).format('L') + ' '
    //
    //       if (output.length == 0) {
    //         if (key == 'isArchived') {
    //           output.push(moment(workflow.logs[workflow.logs.length - 1].at).format('L') + ' : ' + i18n.t('archiving'))
    //         } else {
    //           output.push(moment(workflow.logs[workflow.logs.length - 1].at).format('L') + ' : ' + i18n.t(key))
    //         }
    //       } else {
    //         if (key == 'isArchived') {
    //           output.push(i18n.t('archiving'))
    //         } else {
    //           output.push(i18n.t(key))
    //         }
    //       }
    //
    //
    //
    //     })
    //
    //     return output.join(', ')
    //   }
    //
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().params != null && ('workflowId' in getRoute().params)) {
      console.log("A")

      store.dispatch("workflow/getWorkflow", getRoute().params.workflowId).then(() => {
        fetchForDetails()
      })

      // fetchWorkflows(getRoute().params.workflowId).then(() => {
      //   fetchForDetails()
      // })
    } else {
      fetchWorkflows().then(() => {
        console.log("B")
        // fetchForDetails()
        fetchUsers2()
        // fetchCompanies()
      })
    }

    // fetchWorkflows().then(() => {
    // fetchCalendars(workflow)
    // })

    // fetchArticles()
    // fetchCompanies()
    // fetchIncomingInvoices()
    // fetchOffers()
    // fetchOrders()
    // fetchOutgoingInvoices()
    // fetchPaymentMethods()
    // fetchPaymentTerms()
    // fetchVats()
    // fetchUsers2().then(
    // fetchWorkflows()
    // )

    resetWorkflow()

    return {
      // Components
      capitalize,

      // Data
      currentWorkflow,
      workflowModalShow,
      workflowModalTitle,
      isMounted,

      // Computed
      workflow,

      // Methods
      addWorkflow,
      selectWorkflow,
      duplicateWorkflow,
      submitValidatedWorkflowLocal,
      doneActivity,
      submitValidatedActivity,
      cancelPlanifiedActivity,
      activateWorkflowLocal,
      archivateWorkflowLocal,
      submitValidatedNote,
      removeWorkflowLocal,
      calendarDatesChange,
      displayLastLogs,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteWorkflowAlert (quote) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theWorkflow') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeWorkflowLocal(quote)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}