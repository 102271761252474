<template>
  <b-row>

    <!--          Type-->
    <b-col md="6">
      <field-select
          :model.sync="activity.type"
          :name="'type'"
          :options="types"
          :label="'name'"
          :reduce="'value'"
          :isRequired="true"
      />
    </b-col>

    <!--          due date-->
    <b-col md="6">
      <field-date
          :name="'dueDate'"
          :model.sync="activity.dueDate"
      />
    </b-col>

    <!--          Summary-->
    <b-col md="6">
      <field-input
          :name="'summary'"
          :model.sync="activity.summary"
      />
    </b-col>

    <!--          Assign to-->
    <b-col md="6">
      <field-employee
          :name="'assignTo'"
          :model.sync="activity.assignedTo"
          :isRequired="true"
          :clearable="false"
      />
    </b-col>

    <!--          Description-->
    <b-col md="12">
      <field-textarea
          :model.sync="activity.description"
          :name="'description'"
          :placeholder="'description'"
          :isRequired="true"
          style="overflow: auto; max-height: 135px;"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import i18n from '@/libs/i18n'
import FieldInput from '@/components/input/Input'
import FieldContact from '@/components/input/Contact'
import FieldEmployee from '@/components/input/Employee'
import FieldDate from '@/components/input/Date'
import FieldSelect from '@/components/input/Select'
import FieldTextarea from '@/components/input/Textarea'
import store from '@/store'

export default {
  components: {
    FieldInput,
    FieldContact,
    FieldEmployee,
    FieldDate,
    FieldSelect,
    FieldTextarea
  },
  props: {
    activity: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const types = ref([
      {
        'name': capitalize(i18n.t('email')),
        'value': 'email'
      },
      {
        'name': capitalize(i18n.t('call')),
        'value': 'call'
      },
      {
        'name': capitalize(i18n.t('appointment')),
        'value': 'appointment'
      },
      {
        'name': capitalize(i18n.t('toDo')),
        'value': 'toDo'
      },
      {
        'name': capitalize(i18n.t('reminder')),
        'value': 'reminder'
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      types,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>