<template>
  <div>
<!--    <outgoingInvoice-home-->
<!--        v-if="workflow"-->
<!--        :outgoingInvoices="workflow.outgoingInvoices"-->
<!--    />-->

    <customer-invoices-view

        @reloadOffers="reloadCustomerInvoices($event)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import outgoingInvoiceHome from '@/components/home/outgoingInvoice/OutgoingInvoice'
import CustomerInvoicesView from '../../../../components/views/CustomerInvoicesView.vue'
import useAPI from '../../../../utils/useAPI'
import router from '../../../../router'

export default {
  components: {
    outgoingInvoiceHome,
    CustomerInvoicesView
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      fetchAutocompleteArticles,
      fetchAutocompleteContacts,
      fetchAutocompletePaymentMethods,
      fetchAutocompletePaymentTerms,
      fetchAutocompleteVats,
      fetchAutocompleteWorkflows,
      fetchOutgoingInvoices2
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const reloadCustomerInvoices = (event) => {
      let payload = event
      if (event == null) {
        payload = {
          searchCriteria: [
            {
              column_name: 'workflow',
              comparator: '=',
              search_text: router.history.current.params.workflowId
            }
          ]
        }
      } else {
        let scWorkflowPosition = event.searchCriteria.find(sc => sc.column_name == 'workflow')
        if (scWorkflowPosition == undefined) {
          payload.searchCriteria.push({
            column_name: 'workflow',
            comparator: '=',
            search_text: router.history.current.params.workflowId
          })
        } else {
          payload.searchCriteria[scWorkflowPosition] = {
            column_name: 'workflow',
            comparator: '=',
            search_text: router.history.current.params.workflowId
          }
        }
      }

      fetchOutgoingInvoices2(payload)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    reloadCustomerInvoices()

    fetchAutocompleteArticles()
    fetchAutocompleteContacts()
    fetchAutocompletePaymentMethods()
    fetchAutocompletePaymentTerms()
    fetchAutocompleteVats()
    fetchAutocompleteWorkflows()

    return {
      // Components

      // Data

      // Computed

      // Methods
      reloadCustomerInvoices,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>